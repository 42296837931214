<template>
  <div class="hello">
    <div v-if="user">
      <img alt="User Profile Image" :src="$store.state.authObject.user.photoURL">
      <h1>Hello {{ user.profile.name }}</h1>
      <h3>User Profile:</h3>
      <p>
        The user profile information was provided by and automatically loaded from {{user.providerId}}. <br/>
        It is updated on each new log-in, and stored in the Firestore <i>user</i> collection.
      </p>
      <ul>
        <li><span class="list_key">Firebase UID</span> : {{uid}}</li>
        <li v-for="(key, number) in Object.keys(user.profile)" :key="number"><span class="list_key">{{key}}</span> : {{user.profile[key]}}</li>
      </ul>
      <h3>Authentication info:</h3>
      <p>
        The authentication information is loaded from {{user.providerId}} on each new authentication request by the app. <br/>
        For example, when re-opening browser, refreshing a page or when token is expired.
      </p>
      <ul>
        <li>Token: {{$store.state.authObject.user.token}}</li>
        <li v-for="(pr_key, num) in Object.keys($store.state.authObject.user.providerData[0])" :key="num"><span class="list_key">{{pr_key}}</span> : {{$store.state.authObject.user.providerData[0][pr_key]}}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import { userRef } from '@/plugins/firebaseInit'

export default {
  name: 'User',
  props: {
    // user: Object,
    uid: String
  },
  data () {
    return {
      user: null
    }
  },
  methods: {
    getUser () {
      const that = this
      userRef.doc(this.uid).get()
        .then(function (DocumentSnapshot) {
          that.user = DocumentSnapshot.data()
        })
    }
  },
  created () {
    this.getUser()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: square;
  display: inline-block;
  text-align: left;
  padding: 10px;
}

li {
  /*display: list-item;*/
  /*margin: 10px;*/
  white-space: normal;
  float: left;
  width: 100%;
  height: auto;
  /*word-wrap: break-word;*/
  word-wrap: anywhere;
}

a {
  color: #42b983;
}

.hello {
  width: 900px;
  margin: 0 auto;
}
.list_key {
  display: inline-block;
  text-align: end;
  min-width: 150px;
}
</style>
