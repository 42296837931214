<template>
  <div class="user">
    <div v-if="$store.state.authObject">
      <HelloUser :uid=$store.state.authObject.user.uid />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloUser from '@/components/HelloUser.vue'

export default {
  name: 'User',
  components: {
    HelloUser
  }
}
</script>
